<script>
import { mapState } from 'vuex';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { MuiAlgoliaList } from '@emobg/vue-internal';
import { aclService } from '@emobg/access-utils';
import { DELAY } from '@emobg/web-utils';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';

import { DEFAULT_HITS_PER_PAGE } from '@/constants/algoliaTable';

import { userData } from '@/stores/User/UserData/UserDataMapper';

import { VIEW_BOOKINGS_COMPANY_EMPLOYEES, VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS } from '@/constants/permissions';

import EditBookingTimeModal from '@/components/MyBookings/modals/EditBookingTimeModal';
import CancelBookingModal from '@/components/MyBookings/modals/CancelBookingModal';
import EditDropOffBookingModal from '@/components/MyBookings/modals/EditDropOffBookingModal';
import EditBookingPeopleModal from '@/components/MyBookings/modals/EditBookingPeopleModal';

import { genericError } from '@/utils/publicImages';
import { useBookingModal } from '@/components/MyBookings/modals/composable/BookingModal';
import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useAlgolia } from '@/composable/Algolia/useAlgolia';

import {
  getCircles,
  getEmployeeUuids,
  getPermissionsOnBooking,
} from './helpers/permissions';
import { canAddVehicleUsers, isOneWayEnabled } from './helpers/csOperatorValidations';
import { isActiveBooking, isStartedBooking } from './helpers/bookings';
import { employeeBookingsColumns, employeeBookingsFacets } from './helpers/tableConfig';
import {
  employeeBookingsFilters,
  getEditBookingReturnLocation,
  getEditBookingTime,
  toEditBookingPeopleData,
} from './helpers/algolia';
import { LABELS } from './constants/labels.const';

// TODO: Refactor header styles https://europcarmobility.atlassian.net/browse/CF-576
export default {
  name: 'EmployeeBookingsComponent',

  components: {
    EditBookingTimeModal,
    CancelBookingModal,
    MuiAlgoliaList,
    EditDropOffBookingModal,
    EditBookingPeopleModal,
  },
  setup() {
    const {
      apiKey,
      algoliaConfig,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
    } = useAlgolia();
    const {
      isModalOpen: isEditTimeModalOpen,
      openModal: openEditTimeModal,
      closeModal: closeEditTimeModal,
      bookingDataModal: editTimeBooking,
    } = useBookingModal();
    const {
      isModalOpen: isCancelBookingModalOpen,
      openModal: openCancelBookingModal,
      closeModal: closeCancelBookingModal,
      bookingDataModal: cancelBooking,
    } = useBookingModal();
    const {
      isModalOpen: isEditDropOffBookingModalOpen,
      openModal: openEditDropOffBookingModal,
      closeModal: closeEditDropOffBookingModal,
      bookingDataModal: dropOffBooking,
    } = useBookingModal();
    const { trackModalView, trackPageView } = useTrackingGTM();
    const { notifyError } = useNotifications();
    const app = document.getElementById('app');
    return {
      isEditTimeModalOpen,
      openEditTimeModal,
      closeEditTimeModal,
      editTimeBooking,
      isCancelBookingModalOpen,
      openCancelBookingModal,
      closeCancelBookingModal,
      cancelBooking,
      isEditDropOffBookingModalOpen,
      openEditDropOffBookingModal,
      closeEditDropOffBookingModal,
      dropOffBooking,
      trackModalView,
      trackPageView,
      notifyError,
      algoliaConfig,
      initAlgolia,
      apiKey,
      refreshAlgoliaTable,
      app,
      getAlgoliaLabels,
      LABELS,
    };
  },
  data() {
    return {
      isLoading: false,
      isListLoading: false,
      store: null,
      cache: {},
      additionalDriversFilter: {},
      algoliaFilters: null,
      circles: [],
      dedicatedOperator: null,
      modals: {
        editBookingPeople: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    userData,
    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      { costAllocationList: state => get(state, 'COST_ALLOCATION_DATA.costAllocationList', []) },
    ),
  },

  async created() {
    this.isLoading = true;
    this.genericErrorIllustration = genericError;
    this.ALGOLIA_QUERY_PARAMETERS = {
      hitsPerPage: DEFAULT_HITS_PER_PAGE,
    };
    this.TABLE_LABELS = this.getAlgoliaLabels(this.LABELS);

    const companyOperatorUuid = get(this, 'userData.company.dedicated_fleet_cs_operator');
    if (companyOperatorUuid) {
      this.dedicatedOperator = await this.fetchCSOperator(companyOperatorUuid);
    }

    this.operatorConfiguration = get(this, 'dedicatedOperator.configuration');

    const companyUuid = get(this, 'userData.company.uuid');
    const userUuid = get(this, 'userData.uuid');
    const userEmail = get(this, 'userData.email');

    const hasRegularViewBookingsPermission = aclService.hasUserPermissions(
      [VIEW_BOOKINGS_COMPANY_EMPLOYEES],
      [VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS],
    );

    let circlesEmployees;
    if (!hasRegularViewBookingsPermission) {
      const circleUuids = aclService.getCirclesByPermission(VIEW_BOOKINGS_COMPANY_EMPLOYEES);
      this.circles = await getCircles(this.notifyError, circleUuids);
      circlesEmployees = getEmployeeUuids(this.circles);
    }

    const onBehalfUserUuid = aclService.hasUserPermissions(VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS) ? userUuid : null;
    this.algoliaFilters = employeeBookingsFilters({
      companyUuid,
      circlesEmployees,
      ownerUuid: onBehalfUserUuid,
      creatorUuid: onBehalfUserUuid,
    });
    await this.initAlgolia('cs_webapp_cs_bookings_employees', this.algoliaFilters);

    const isOperatorAllowingOneWay = get(this, 'operatorConfiguration.has_one_way');

    this.TABLE_FACETS = employeeBookingsFacets({
      userEmail,
    });

    this.ITEMS_ACTIONS = [
      {
        label: this.$t('common.booking_dropdown.edit_time'),
        method: booking => {
          this.openEditTimeModal({ booking, transformer: getEditBookingTime });
          this.trackModalView({ modalName: GTMModalEvents.editEmployeeBookingTime });
        },
        isVisible: booking => {
          const { isEditable } = getPermissionsOnBooking(this, booking, this.circles);

          return isEditable && isActiveBooking(get(booking, 'status'));
        },
      },
      {
        label: this.$t('common.booking_dropdown.edit_dropoff_location'),
        method: booking => {
          try {
            this.openEditDropOffBookingModal({ booking, transformer: getEditBookingReturnLocation });
            this.trackModalView({ modalName: GTMModalEvents.editEmployeeBookingReturnLocation });
          } catch {
            this.notifyError({
              text: this.$t('notifications.whooops'),
              textAction: this.$t('buttons.ok'),
            });
          }
        },
        isVisible: booking => {
          const oneWayEnabled = isOneWayEnabled(this, booking, this.circles, this.operatorConfiguration);

          return isActiveBooking(get(booking, 'status')) && oneWayEnabled;
        },
      },
      {
        label: this.$t('buttons.edit_people_from_trip'),
        method: booking => {
          this.trackModalView({ modalName: GTMModalEvents.editEmployeeBookingPeople });
          this.openAdditionalVehicleUsers(booking);
        },
        isVisible: booking => {
          const {
            canAddDrivers,
            canAddPassengers,
          } = canAddVehicleUsers(this, booking, this.circles, this.operatorConfiguration);

          return (canAddDrivers || canAddPassengers) && isActiveBooking(get(booking, 'status'));
        },
      },
      {
        label: this.$t('common.booking_dropdown.cancel_booking'),
        class: 'emobg-color-danger',
        method: booking => {
          const bookingTransformer = ({ uuid }) => ({ uuid, isBookingRequest: false });

          try {
            this.openCancelBookingModal({ booking, transformer: bookingTransformer });
            this.trackModalView({ modalName: GTMModalEvents.cancelEmployeeBooking });
          } catch {
            this.notifyError({
              text: this.$t('notifications.whooops'),
              textAction: this.$t('buttons.ok'),
            });
          }
        },
        isVisible: booking => {
          const { isCancelable } = getPermissionsOnBooking(this, booking, this.circles);
          return isCancelable && !isStartedBooking(get(booking, 'usage_status'));
        },
      },
    ];

    this.TABLE_CONFIG = employeeBookingsColumns({
      costAllocationList: this.costAllocationList,
      isOperatorAllowingOneWay,
      rowActions: this.ITEMS_ACTIONS,
    });

    this.isLoading = false;
  },

  methods: {
    isEmpty,
    fetchCSOperator,
    openAdditionalVehicleUsers(booking) {
      const {
        canAddDrivers,
        canAddPassengers,
      } = canAddVehicleUsers(this, booking, this.circles, this.operatorConfiguration);

      this.modals.editBookingPeople = {
        ...toEditBookingPeopleData(booking),
        isAdditionalDriverEnabled: Boolean(canAddDrivers),
        isAdditionalPassengersEnabled: Boolean(canAddPassengers),
        isOpen: true,
      };
    },
    closeTimeModal({ isEdited }) {
      this.closeEditTimeModal();
      this.trackPageView();
      if (isEdited) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeCancelModal(isCanceling) {
      this.closeCancelBookingModal();
      this.trackPageView();

      if (isCanceling) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeEditDropOffModal({ isEditing }) {
      this.closeEditDropOffBookingModal();
      this.trackPageView();
      if (isEditing) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
    closeEditPeopleModal({ isEdited }) {
      this.modals.editBookingPeople.isOpen = false;
      this.trackPageView();
      if (isEdited) {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeBookingsList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },
  },
};
</script>

<template>
  <div class="EmployeeBookingsComponents container pt-4">
    <h1 class="emobg-font-large">
      {{ $t('business_profile.employee_bookings.bookings.title') }}
    </h1>
    <h2 class="emobg-font-medium mb-5">
      {{ $t('business_profile.employee_bookings.bookings.subtitle') }}
    </h2>
    <ui-loader v-if="isLoading" />
    <figure
      v-else-if="isEmpty(algoliaFilters)"
      class="d-flex justify-content-center"
    >
      <img
        :src="genericErrorIllustration"
        class="w-50"
        data-test-id="employee_bookings_table-failed_image"
        alt="error"
      >
    </figure>
    <MuiAlgoliaList
      v-else
      ref="muiAlgoliaEmployeeBookingsList"
      :loading="isListLoading"
      :config="algoliaConfig"
      :api-key="apiKey"
      :filters="algoliaFilters"
      :facets="TABLE_FACETS"
      :table-config="TABLE_CONFIG"
      :labels="TABLE_LABELS"
      :no-data-label="FALLBACK_MESSAGE.dash"
      :query-parameters="ALGOLIA_QUERY_PARAMETERS"
      :scroll-target="app"
      index="cs_webapp_cs_bookings_employees"
      class="EmployeeBookingsList"
    />
    <EditBookingTimeModal
      v-if="isEditTimeModalOpen && editTimeBooking"
      :booking="editTimeBooking"
      :is-open="isEditTimeModalOpen"
      @on:close="closeTimeModal"
    />
    <CancelBookingModal
      v-if="isCancelBookingModalOpen && cancelBooking"
      :is-open="isCancelBookingModalOpen"
      :booking="cancelBooking"
      @on:close="closeCancelModal"
    />
    <EditDropOffBookingModal
      v-if="isEditDropOffBookingModalOpen && !isEmpty(dropOffBooking)"
      :is-open="isEditDropOffBookingModalOpen"
      :booking="dropOffBooking"
      @on:close="closeEditDropOffModal"
    />
    <EditBookingPeopleModal
      v-model="modals.editBookingPeople.isOpen"
      v-bind="modals.editBookingPeople"
      @on:close="closeEditPeopleModal"
    />
  </div>
</template>
