import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import kebabCase from 'lodash/kebabCase';

import { FALLBACK_MESSAGE, compactArray, getValue } from '@emobg/web-utils';
import { bookingDuration } from '@/helpers/booking/bookingHelpers';

import { useTranslations } from '@/composable/App/Translations';

import { FLEET_TYPES } from '@/constants/fleetTypes.const';
import { BOOKING_STATUS } from '@/constants/bookingStatus.const';
import { BOOKING_USAGE_STATUS } from '@/constants/bookingUsageStatus.const';

const { $t } = useTranslations();

export const isActiveBooking = status => {
  const bookingStatus = toLower(status);
  return includes(
    [BOOKING_USAGE_STATUS.started, BOOKING_STATUS.booked, kebabCase(BOOKING_STATUS.preBooked)], bookingStatus,
  );
};

export const isStartedBooking = status => toLower(status) === BOOKING_USAGE_STATUS.started;

export const isDedicatedBooking = booking => {
  const bookingFleetType = getValue(booking, 'fleet', undefined) || getValue(booking, 'cs_booking_use_case.fleet', undefined);
  return toLower(bookingFleetType) === FLEET_TYPES.dedicated;
};

export const isFinishedBooking = status => toLower(status) === toLower(BOOKING_USAGE_STATUS.finished);

export const transformDuration = (bookingStart, bookingEnd) => {
  try {
    const { days, hours, minutes } = bookingDuration(bookingStart, bookingEnd);

    return compactArray([
      days ? $t('common.format_days', { count: days }, days) : null,
      hours ? $t('common.format_hours', { count: hours }, hours) : null,
      minutes ? $t('common.format_minutes', { count: minutes }, minutes) : null,
    ]).join(' ');
  } catch {
    return FALLBACK_MESSAGE.dash;
  }
};
